import React from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import { StaticImage } from 'gatsby-plugin-image';

const EmblaCarousel = ({ slides }) => {
  const [viewportRef] = useEmblaCarousel({
    align: 'start',
    skipSnaps: false,
  });

  return (
    <div className="embla ml-2 text-primary">
      <div className="embla__viewport" ref={viewportRef}>
        <div className="embla__container">
          <a
            href={process.env.GATSBY_QUIZ_LINK}
            target="_blank"
            rel="noreferrer"
          >
            <div className="my-4 mx-2 p-4 pb-10 bg-white w-56 rounded-lg relative">
              <div className="font-bold">
                Quiz
                <StaticImage
                  src="../../images/v-right.png"
                  alt="hw-logo"
                  placeholder="blurred"
                  layout="fixed"
                  width={6}
                  height={9}
                  className="inline-block ml-4"
                />
              </div>
              <div>
                Save time in
                <br />
                Correction
              </div>

              <div className="">
                <StaticImage
                  src="../../images/quiz.png"
                  alt="hw-logo"
                  placeholder="blurred"
                  layout="fixed"
                  width={100}
                  height={100}
                  className="cabsolute w-32 bottom-0 right-0"
                />
              </div>
            </div>
          </a>

          <a
            href={process.env.GATSBY_CASE_STUDY_LINK}
            target="_blank"
            rel="noreferrer"
          >
            <div className="my-4 mx-2 p-4 pb-10 bg-white w-56 rounded-lg relative">
              <div className="font-bold">
                Case Study
                <StaticImage
                  src="../../images/v-right.png"
                  alt="hw-logo"
                  placeholder="blurred"
                  layout="fixed"
                  width={6}
                  height={9}
                  className="inline-block ml-4"
                />
              </div>
              <div>
                Get case study
                <br />
                questions
              </div>

              <div className="">
                <StaticImage
                  src="../../images/daily-homework.png"
                  alt="hw-logo"
                  placeholder="blurred"
                  layout="fixed"
                  width={100}
                  height={100}
                  className="cabsolute w-32 bottom-0 right-0"
                />
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default EmblaCarousel;
