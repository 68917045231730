import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import BookCarousel from './bookCarousel';

const routes = require('../../routes');

const getColor = (subject, suffix, classnames) => {
  let color = 'cbrown';
  if (subject === 'Maths') color = 'cyellow';
  else if (subject === 'Science' || subject === 'Environmental Studies') color = 'cblue';
  else if (subject === 'English') color = 'cred';
  else if (subject === 'Social Science' || subject === 'Social Studies') color = 'cgreen';
  else color = 'cbrown';

  return `${color}${suffix} ${classnames}`;
};

const BookContainer = ({ class_obj }) => (
  <div
    className={getColor(class_obj.subject, '100', 'rounded-lg p-4 my-4 mx-2')}
  >
    <div className="w-full">
      <span
        className={getColor(
          class_obj.subject,
          '200',
          'text-white font-bold p-2 items-center rounded',
        )}
      >
        {class_obj.class_name}
      </span>
      <span className="font-extrabold text-xl p-2">
        {' '}
        {class_obj.subject}
      </span>
      <span className="font-extrabold text-2xl float-right	">
        <Link
          to={`/${routes.class}/${class_obj.class_name}/${class_obj.subject}`}
        >
          <StaticImage
            src="../../images/v-right.png"
            alt="hw-logo"
            placeholder="blurred"
            layout="fixed"
            width={12}
            height={18}
            className="inline-block ml-4"
          />
        </Link>
      </span>
    </div>

    <BookCarousel books={class_obj.books} class_obj={class_obj} />
  </div>
);

export default BookContainer;
