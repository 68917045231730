import React from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import { StaticImage } from 'gatsby-plugin-image';
import { Link, navigate } from 'gatsby';
import CustomLink from '../base/Link';

const routes = require('../../routes');

const EmblaCarousel = ({ books, class_obj }) => {
  const [viewportRef] = useEmblaCarousel({
    align: 'start',
    skipSnaps: false,
  });

  return (
    <div className="embla mt-2">
      <div className="embla__viewport" ref={viewportRef}>
        <div className="embla__container">
          {books.map((book) => (
            <div key={book.book_id}>
              <CustomLink
                to={`${routes.book}/${class_obj.class_name}/${class_obj.subject}/${book.name}-${book.book_id}`}
                firebaseEvent={{
                  eventName: 'book_click',
                  eventParams: { value: book.name, id: book.book_id },
                }}
              >
                <div className="embla__slide bg-white rounded-lg shadow-md overflow-hidden w-44 mr-2 my-2">
                  <img
                    src={book.photo_url}
                    alt="book1"
                    className="w-full h-56 object-cover"
                  />
                  <div className="m-4">
                    <span className="font-semibold">{book.name}</span>
                    <span className="block font-light">
                      {book.content_count}
                      {' '}
                      papers
                    </span>
                  </div>
                </div>
              </CustomLink>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default EmblaCarousel;
