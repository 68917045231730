import React from 'react';
import { graphql } from 'gatsby';
import IndexComponent from '../components/index';
import logFirebaseEvent from '../utils/firebase';

const IndexPage = ({ data }) => {
  const {
    books: { nodes },
  } = data;
  const {
    data: {
      home: { classes_to_books },
    },
  } = nodes[0];

  React.useEffect(() => {
    logFirebaseEvent('home_open', {});
  }, []);

  return <IndexComponent classes_to_books={classes_to_books} />;
};

export default IndexPage;

export const query = graphql`
  query BooksQuery {
    books: allRestApiRoot2TsV2WebsitesSamplePapersHome {
      nodes {
        data {
          home {
            classes_to_books {
              class_name
              sorting_tag
              subject
              books {
                book_id
                content_count
                content_type
                photo_url
                name
                question_count
              }
            }
          }
        }
      }
    }
  }
`;
