import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import Layout from '../base/layout';
import Seo from '../base/seo';
import Banner from './banner';
import BookContainer from './bookContainer';

const IndexComponent = ({ classes_to_books }) => (
  <Layout>
    <Seo title={process.env.GATSBY_HOME_TITLE} />

    <main className="">
      <div className="m-4">
        <div className="bg-secondary rounded-lg overflow-hidden">
          <Banner />
        </div>
      </div>

      <div className="my-4">
        <h1 className="font-extrabold text-2xl my-2 mx-4">
          Download sample paper PDFs
        </h1>

        {classes_to_books.map((class_obj, idx) => (
          <BookContainer class_obj={class_obj} key={idx} />
        ))}
      </div>
    </main>
  </Layout>
);

export default IndexComponent;
